@charset "utf-8";


$top-offset: 3em;

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials
@import "author-bottom"; // bottom author styles

.sidebar__image {
  display: table-cell;
  vertical-align: top;
  width: 36px;
  height: 36px;

  @include breakpoint($large) {
    display: block;
    width: auto;
    height: auto;
  }

  img {
    max-width: 110px;

    @include breakpoint($large) {
      padding: 5px;
    }
  }
}

.summary{
  display: none;

  @include breakpoint($large) {
    display: inherit;
  }
}

p>code, li>code {
  background-color: #f3ecee!important;
  color: #c7254e;
}

.masthead {
  position: fixed;
  top: 0;
  width: 100%;
  background: #f2f3f3;  // set a color to hide content that may appear below masthead

  @media (min-width: 48em) {
    .masthead {
      height: $top-offset;  // need a magic number here which may break in different viewports
    }
    .masthead__inner-wrap {
      padding: 0.5em;
    }
  }
}

.masthead__inner-wrap {
  padding: 0em 0em 0em 0.5em;
}

body {
  padding: #{$top-offset - 1} 0 0;  // padding-top equal to masthead height or greater
  overflow-x: hidden;
}

@media (min-width: 48em) {
  body {
    padding: $top-offset 0 0;  // padding-top equal to masthead height or greater
  }
}

@media (min-width: 64em) {
  .sidebar__right.sticky {
    top: #{$top-offset+2};
    float: right;
  }
}

.greedy-nav {
  background: #f2f3f3;
}

.sticky {
  top: $top-offset;
}

html {
  font-size: 14px;

  @include breakpoint($medium) {
    font-size: 16px;
  }

  @include breakpoint($large) {
    font-size: 18px;
  }

  @include breakpoint($x-large) {
    font-size: 20px;
  }
}

.page__footer-links {
  font-family: $global-font-family;
  font-size: $type-size-7;
  text-align: right;
  a {
    padding: 0 1em;
  }
}

.archive__item-title {
  a {
     color: inherit;
   }
}

.archive__subtitle {
  padding-top: $top-offset;
}

.toc {
  margin-bottom: 1em;
}

.toc__category,
.toc__recent {
  display: none;

  @include breakpoint($large) {
    display: inherit;
  }
}

ins.adsbygoogle {
  border-bottom: none !important;
}

// more button colors
.btn {
  /* button colors */
  $buttoncolors:
  (reddit, $reddit-color),
  (tumblr, $tumblr-color),
  (xing, $xing-color),
  (github, $github-color),
  (gitlab, $gitlab-color);

  @each $buttoncolor, $color in $buttoncolors {
    &--#{$buttoncolor} {
      @include yiq-contrasted($color);
      @if ($buttoncolor == inverse) {
        border: 1px solid $border-color;
      }
      @if ($buttoncolor == light-outline) {
        border: 1px solid #fff;
      }

      &:visited {
        @include yiq-contrasted($color);
      }

      &:hover {
        @include yiq-contrasted(mix(#000, $color, 20%));
      }
    }
  }
}

.feature_wrapper {
  @include clearfix();
  margin-bottom: 2em;

  .archive__item-title {
    margin-bottom: 0;
  }
}

// more spacing in feature blocks
.feature_item {
  position: relative;
  margin-bottom: 2em;
  font-size: 1em;

  @include breakpoint($small) {
    float: left;
    width: 29%;

    &:nth-child(3n + 1) {
      clear: both;
      margin-left: 0;
    }

    &:nth-child(3n + 2) {
      clear: none;
      margin-left: 6%;
    }

    &:nth-child(3n + 3) {
      clear: none;
      margin-left: 6%;
    }
  }

  h3 {
    font-size: 1em;
  }
}

// border and spacing in the nav nav__list
.nav__sub {
  border-bottom: 1px solid #f2f3f3;
  padding-bottom: 0.25em;
  margin-bottom: 0.25em;
}

.feature__title, .gallery__title {
  padding-top: 1.5em;
}

.feature__item--left li, .feature__item--right li{
  list-style: none;
}

.splash__post__wrapper {
    clear: both;
    margin-bottom: 2em;
    display: flow-root;
    border-bottom: 1px solid #f2f3f3;

    h2 {
      border-bottom: none;
      margin-block-start: 0;
    }
}

.splash__post__item {
  position: relative;
  font-size: 1.125em;

  @include breakpoint($small) {
    float: left;
    margin-bottom: 1.25em;
    width: span(6 of 12);

    &:nth-child(2n + 1) {
      clear: both;
      margin-left: 0;
    }

    &:nth-child(2n + 2) {
      clear: both;
      margin-left: 0;
    }

    &:nth-child(2n + 3) {
      clear: none;
      margin-left: gutter(of 12);
    }
  }

  li {
    list-style: none;
  }

  h2 {
    border-bottom: none;
    margin: 0;
    padding: 0;
    font-size: 1em;
  }
  a:not(.btn) {
    text-decoration: none;
    color: inherit;
  }
}

.splash__post__nav {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
  list-style-position: outside;

  li {
    border-top: 1px solid #f2f3f3;
    margin-bottom: 0;
  }
  a {
    display: block;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }
  a:hover {
    background: #f2f3f3;
  }
}

.footer-links {
  a {
    white-space: nowrap;
  }
}

figure {
  &.fourth {
    > a,
    > img {
      @include breakpoint($small) {
        width: calc(25% - 0.5em);
      }
    }

    figcaption {
      width: 100%;
    }
  }
}

.full-width-background {

  background: #efefef;
  position: relative; /* for child pseudo-element */
  z-index: 0;
  margin: 0 -1em;
  padding: 0.25rem 1em;

  @include breakpoint($large) {
    margin: 0 -600rem;
    /* add back negative margin value */
    padding: 0.25rem 600rem;
  }
}

#purchase>a.paddle_button, #purchase>a.paddle_button:hover {
  display: inline-block;
  margin-bottom: 0.25em;
  padding: 0.5em 1em;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", Arial, sans-serif;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  text-decoration: none !important;
  border-width: 0;
  border-radius: 4px;
  cursor: pointer;
}

.iframe-container {
  overflow: hidden;
  padding-top: 99%;
  position: relative;
}
.iframe-container iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}

.version-info {
  padding: 0.25em 0.5em;
  color: $text-color;
  font-family: $global-font-family;
  font-size: $type-size-6 !important;
  text-indent: initial; /* override*/
  background-color: mix($background-color, $warning-color, $notice-background-mix);
  border-radius: $border-radius;
  box-shadow: 0 1px 1px rgba($warning-color, 0.25);
}